import atk from './atk';
import cco from './cco';
import cio from './cio';
import defaults from './defaults';
import newsletter from './newsletter';
import parents from './parents';
import play from './play';
import registration from './registration';

const themes = {
  atk,
  cco,
  cio,
  defaults,
  newsletter,
  parents,
  play,
  registration,
};

export default themes;
